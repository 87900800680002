import React, {useEffect} from 'react';
import Nav from './Nav';
import styles from './CSS/Scene3.css';
// import BackgroundCard3 from '../Resources/BackgroundCard3.png';
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Scene3 = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    const genAnim = (delay, fromLeft) => {
        if (fromLeft) {
            return {
                visible: { opacity: 1, x:0, transition: {
                    duration: 0.75,
                    ease: "easeInOut",
                    delay: delay
                }},
                hidden: { opacity: 0, x:-100}
            }
        } else {
            return {
                visible: { opacity: 1, x:0, transition: {
                    duration: 0.75,
                    ease: "easeInOut",
                    delay: delay
                }},
                hidden: { opacity: 0, x:100}
            }
        }
    }

    const genAnimBack = (delay, fromLeftUp) => {
        if (fromLeftUp) {
            return {
                visible: { opacity: 1, x:0, y:0, rotate: -30, transition: {
                    duration: 3,
                    ease: "easeOut",
                    delay: delay
                }},
                hidden: { opacity: 0, x:-300, y:-300, rotate: 0}
            }
        } else {
            return {
                visible: { opacity: 1, x:0, y:0, rotate: -20, transition: {
                    duration: 4,
                    ease: "easeOut",
                    delay: delay
                }},
                hidden: { opacity: 0, x:500, y:300, rotate: -50}
            }
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    const styleBack = {
        // background: `url(${BackgroundCard3})`,
        background: `#212121`,
        height: '90%',
        width: '95%',
        backgroundColor: '#242424',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    return(
        <div style={styleBack} className={cx("scene","scene3")}>
            <div className={cx("portfolio-wrapper")}>
                <motion.div ref={ref} variants={genAnimBack(0, true)} initial="hidden" animate={control} className={cx("portfolio-back-left")}></motion.div>
                <div className={cx("portfolio-text-wrapper")}>
                    <motion.h1 ref={ref} variants={genAnim(0, false)} initial="hidden" animate={control}>Sorry<span className={cx("sig-dot")}>.</span></motion.h1>
                    <motion.h1 ref={ref} variants={genAnim(0.3, true)} initial="hidden" animate={control}>I'm still working on this website<span className={cx("sig-dot")}>.</span></motion.h1>
                    <motion.h1 ref={ref} variants={genAnim(0.6)} initial="hidden" animate={control}>Check in later for updates<span className={cx("sig-dot")}>.</span></motion.h1>
                </div>
                {/* <div className={cx("portfolio-tiles-wrapper")}>
                    <div className={cx("portfolio-tile")}>
                        <p>Project No 1</p>
                    </div>
                    <div className={cx("portfolio-tile")}>
                        <p>Project No 2</p>
                    </div>
                    <div className={cx("portfolio-tile")}>
                        <p>Project No 3</p>
                    </div>
                    <div className={cx("portfolio-tile")}>
                        <p>Project No 4</p>
                    </div>
                </div> */}
                <motion.div ref={ref} variants={genAnimBack(0, false)} initial="hidden" animate={control} className={cx("portfolio-back-right")}></motion.div>
            </div>
            <Nav curPage={2}/>
        </div>
    )
}

export default Scene3;