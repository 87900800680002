import React, {useState} from 'react';
import styles from './CSS/ContactForm.css'
import classNames from 'classnames/bind';
import Lottie from 'react-lottie';
import loadingAnim from '../Lotties/loading.json'
import errorAnim from '../Lotties/error.json'

export const ContactForm = (props) => {
    const cx = classNames.bind(styles)
    const [form, setForm] = useState(
        {
            fName: null,
            lName: null,
            mail: null,
            message: null,
    })

    const [fName, setFname] = useState({border: "none"})
    const [lName, setLname] = useState({border: "none"})
    const [mail, setEmail] = useState({border: "none"})
    const [message, setMessage] = useState({border: "none"})
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)

    const updateBorder = () => {
        const styleMark = {border: "3px groove #FF8B46"}
        const styleUnmark = {border: "none"}

        !form.fName ? setFname(styleMark) : setFname(styleUnmark);
        !form.lName ? setLname(styleMark) : setLname(styleUnmark);
        !form.mail ? setEmail(styleMark) : setEmail(styleUnmark);
        !form.message ? setMessage(styleMark) : setMessage(styleUnmark);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (form.mail && form.fName && form.lName && form.message) {
            setSending(true)
            fetch("https://pne8w4p99f.execute-api.eu-central-1.amazonaws.com/default", {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(form)
            }).then((res) => {
                setSending(false)
                props.closeHandle(false)
                props.resetHover('send')
            }).catch((err)=>{
                setSending(false)
                setError(true)
            })
        } else {
            updateBorder()
        }
    }

    const animOptionsLoad = {
        loop: true,
        autoplay: true,
        animationData: loadingAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    
    const animOptionsError = {
        loop: false,
        autoplay: true,
        animationData: errorAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return(
        <div className={cx("ContactForm-Wrapper")}>
            <button className={cx("ContactForm-Form-Close")} onClick={() => {props.closeHandle(false); props.resetHover('dehover'); props.deHover()}}/>
            <form className={cx("ContactForm-Form")} onSubmit={handleSubmit}>
                <input style={fName} className={cx("ContactForm-Form-elm", "cf-FName")} type="text" placeholder="First Name" value={form.fName} onChange={(event)=> {setForm({...form, fName: event.target.value})}}/>
                <input style={lName} className={cx("ContactForm-Form-elm", "cf-LName")} type="text" placeholder="Last Name" value={form.lName} onChange={(event)=> {setForm({...form, lName: event.target.value})}}/>
                <input style={mail} className={cx("ContactForm-Form-elm", "cf-EMail")} type="text" placeholder="E-Mail" value={form.mail} onChange={(event)=> {setForm({...form, mail: event.target.value})}}/>
                <textarea style={message} className={cx("ContactForm-Form-elm", "cf-Message")} placeholder="Message" value={form.message} onChange={(event)=> {setForm({...form, message: event.target.value})}}/>
                <input className={cx("ContactForm-Form-elm", "cf-Send")} type="submit" value="Send"/>
                {sending&&
                <div className={cx("ContactForm-Form-overlay")}>
                    <div className="ContactForm-Form-sending-anim-wrapper">
                        <Lottie className={cx("ContactForm-Form-sending-anim")} options={animOptionsLoad}/>
                    </div>
                </div>
                }
                {error&&
                <div className={cx("ContactForm-Form-overlay")}>
                    <div className={cx("ContactForm-Form-error-wrapper")}>
                        <div className="ContactForm-Form-error-anim-wrapper">
                            <Lottie className={cx("ContactForm-Form-error-anim")} options={animOptionsError}/>
                        </div>
                        <p className={cx("ContactForm-Form-error-message")}><span style={{color: "#FF8B46"}}>ERROR:</span><br/>There was an error sending the message!<br/>Please try again, or use another contact method.</p>
                        <button className={cx("ContactForm-Form-error-button")} onClick={()=> setError(false)}>Okay</button>
                    </div>
                </div>
                }
            </form>
        </div>
    )
}

export default ContactForm;