import React, {useState, useEffect} from 'react';
import Nav from './Nav';
import styles from './CSS/Scene1.css';
import Picture from '../Resources/me.png';
import Instagram from '../Resources/Instagram.png';
import Twitter from '../Resources/Twitter.png';
import LinkedIn from '../Resources/LinkedIn.png';
import Xing from '../Resources/Xing.png';
import Git from '../Resources/Git.png';
import InstagramHover from '../Resources/Instagram-hover.png';
import TwitterHover from '../Resources/Twitter-hover.png';
import LinkedInHover from '../Resources/LinkedIn-hover.png';
import XingHover from '../Resources/Xing-hover.png';
import GitHover from '../Resources/Git-hover.png';
import BackgroundCard1 from '../Resources/BackgroundCard1.png';
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Scene1 = () => {
    const control = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0,
      });

    const genAnim = (delay, fromLeft, xEnd = 0, xStart = 75, dontMove = false, rotation=0) => {
        if (dontMove){
            return {
                visible: { opacity: 1, transition: {
                    duration: 0.4,
                    ease: "easeInOut",
                    delay: delay
                }},
                hidden: { opacity: 0}
            }
        } else {
            if (fromLeft) {
                return {
                    visible: { opacity: 1, x:xEnd, rotate: rotation, transition: {
                        duration: 0.4,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x:-xStart, rotate: rotation}
                }
            } else {
                return {
                    visible: { opacity: 1, x:xEnd, rotate: rotation, transition: {
                        duration: 0.4,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x:xStart, rotate: rotation}
                }
            }   
        }
    }

    const enumerateDelay = (num) => {
        const baseDelay = .5
        const time = 1
        const max = 15
        return ((time/max)*num)+baseDelay
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    useEffect(()=>{
        // only render card if all big pictures are loaded
        const meImage = new Image()
        meImage.src = Picture
        const backImage = new Image()
        backImage.src = BackgroundCard1

        meImage.onload = () => {
            setIsLoaded(prev => {return {...prev, 'me': true}})
        }
        backImage.onload = () => {
            setIsLoaded(prev => {return {...prev, 'card': true}})
        }
    }, [])

    const cx = classNames.bind(styles)
    
    const [instaHover, setInstaHover] = useState(false)
    const [twitterHover, setTwitterHover] = useState(false)
    const [linkedInHover, setLinkedInHover] = useState(false)
    const [xingHover, setXingHover] = useState(false)
    const [gitHover, setGitHover] = useState(false)
    const [isLoaded, setIsLoaded] = useState({'card': false, 'me': false})
    
    const styleBack = {
        background: `url(${BackgroundCard1})`,
        height: '90%',
        width: '95%',
        backgroundColor: '#242424',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
    
    return(
        Object.values(isLoaded).every(val=>val===true) &&
        <div style={styleBack} className={cx("scene", "scene1")}>
            <div ref={ref} variants={genAnim(enumerateDelay(1), true)} initial="hidden" animate={control} className={cx("intro-text")}>
                <motion.h1 ref={ref} variants={genAnim(enumerateDelay(1), true)} initial="hidden" animate={control}>Tobias Michel<span className={cx("sig-dot")}>.</span></motion.h1>
                <motion.h1 ref={ref} variants={genAnim(enumerateDelay(2), true)} initial="hidden" animate={control}>22 y/o<span className={cx("sig-dot")}>.</span></motion.h1>
                <motion.h1 ref={ref} variants={genAnim(enumerateDelay(3), true)} initial="hidden" animate={control}>Frankfurt am Main, Germany<span className={cx("sig-dot")}>.</span></motion.h1>
            </div>
            <div className={cx("interests-wrapper")}>
                <div className={cx("interests-header")}>
                    <motion.div ref={ref} variants={genAnim(enumerateDelay(4), true)} initial="hidden" animate={control} className={cx("interest-header-line")}/>
                    <motion.div ref={ref} variants={genAnim(enumerateDelay(5), true)} initial="hidden" animate={control} className={cx("interest-header-title")}>
                        <h3>interests</h3>
                    </motion.div>
                    <motion.div ref={ref} variants={genAnim(enumerateDelay(6), true)} initial="hidden" animate={control} className={cx("interest-header-line")}/>
                </div>
                <div className={cx("interests")}>
                    <div className={cx("paragraph1")}>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(7), true)} initial="hidden" animate={control}>Finance.</motion.p>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(8), true)} initial="hidden" animate={control}>Accounting.</motion.p>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(9), true)} initial="hidden" animate={control}>Webdesign.</motion.p>
                    </div>
                    <div className={cx("paragraph2")}>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(10), true)} initial="hidden" animate={control}>Coding.</motion.p>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(11), true)} initial="hidden" animate={control}>Data Science.</motion.p>
                        <motion.p ref={ref} variants={genAnim(enumerateDelay(12), true)} initial="hidden" animate={control}>Music.</motion.p>
                    </div>
                </div>
            </div>
            <div className={cx("me-wrapper")}>
                <div className={cx("me-contianer")}>
                    <div className={cx("me-pic-container")}>
                        <motion.div ref={ref} variants={genAnim(enumerateDelay(4), false, 70, 125, false, -5)} initial="hidden" animate={control} className={cx("me-subtext")}>That's me<span className={cx("sig-dot")}>.</span></motion.div>
                        <motion.img ref={ref} variants={genAnim(enumerateDelay(1), false)} initial="hidden" animate={control} className={cx("me-picture")} src={isLoaded.me && Picture} alt="me." />
                    </div>
                    <div className={cx("me-socials-wrapper")}>
                        <motion.a className={cx("me-social-link")} ref={ref} variants={genAnim(enumerateDelay(11), false)} initial="hidden" animate={control} href="https://www.instagram.com/_tobi.michel_/" onMouseOver={()=>setInstaHover(true)} onMouseOut={()=>setInstaHover(false)}>
                            <img className={cx("me-social")} src={instaHover?InstagramHover:Instagram} alt="Instagram" />
                        </motion.a>
                        <motion.a className={cx("me-social-link")} ref={ref} variants={genAnim(enumerateDelay(12), false)} initial="hidden" animate={control} href="https://twitter.com/TobiasMichel12" onMouseOver={()=>setTwitterHover(true)} onMouseOut={()=>setTwitterHover(false)}>
                            <img className={cx("me-social")} src={twitterHover?TwitterHover:Twitter} alt="Twitter" />
                        </motion.a>
                        <motion.a className={cx("me-social-link")} ref={ref} variants={genAnim(enumerateDelay(13), false)} initial="hidden" animate={control} href="https://www.linkedin.com/in/tobias-michel-756468217/" onMouseOver={()=>setLinkedInHover(true)} onMouseOut={()=>setLinkedInHover(false)}>
                            <img className={cx("me-social")} src={linkedInHover?LinkedInHover:LinkedIn} alt="LinkedIn" />
                        </motion.a>
                        <motion.a className={cx("me-social-link")} ref={ref} variants={genAnim(enumerateDelay(14), false)} initial="hidden" animate={control} href="https://www.xing.com/profile/Tobias_Michel33/cv" onMouseOver={()=>setXingHover(true)} onMouseOut={()=>setXingHover(false)}>
                            <img className={cx("me-social")} src={xingHover?XingHover:Xing} alt="Xing" />
                        </motion.a>
                        <motion.a className={cx("me-social-link")} ref={ref} variants={genAnim(enumerateDelay(15), false)} initial="hidden" animate={control} href="https://github.com/mitscheltobi" onMouseOver={()=>setGitHover(true)} onMouseOut={()=>setGitHover(false)}>
                            <img className={cx("me-social")} src={gitHover?GitHover:Git} alt="Git Hub" />
                        </motion.a>
                    </div>
                        {/* <motion.div ref={ref} variants={genAnim(enumerateDelay(14), false)} initial="hidden" animate={control} className={cx("me-blogref")}>Go see my <a href="/blog" id={cx("me-blogref-link")}><span className={cx("sig-dot")}>Blog!</span></a></motion.div> */}
                </div>
                </div>
            <Nav curPage={0}/>
        </div>
    )
}

export default Scene1;