import React, {useEffect, useState} from 'react';
import styles from './CSS/Mobile.css'
import classNames from 'classnames/bind';
import Picture from '../Resources/me.png';
import Instagram from '../Resources/Instagram.png';
import Twitter from '../Resources/Twitter.png';
import LinkedIn from '../Resources/LinkedIn.png';
import Xing from '../Resources/Xing.png';
import Git from '../Resources/Git.png';
import Email from '../Resources/Email-white.png'
import BackTriangle from '../Resources/BackgroundTriangle.png';
import Rating from './Rating';
import CvElement from './CvElement';
import cvPDF from '../Resources/CV-Tobias_Michel.pdf'

export const Mobile = () => {
    const cx = classNames.bind(styles)
    const styleBack = {
        background: '#242424',
        height: '100%',
        width: '100%',
    }

    useEffect(()=>{
        // only render card if all big pictures are loaded
        const meImage = new Image()
        meImage.src = Picture
        const backImage = new Image()
        backImage.src = BackTriangle

        meImage.onload = () => {
            setIsLoaded(prev => {return {...prev, 'me': true}})
            setMePic(Picture)
        }
        backImage.onload = () => {
            setIsLoaded(prev => {return {...prev, 'card': true}})
            setBackPic(BackTriangle)
        }

    }, [])

    const [isLoaded, setIsLoaded] = useState({'card': false, 'me': false})
    const [mePic, setMePic] = useState()
    const [backPic, setBackPic] = useState()

    return(
        Object.values(isLoaded).every(val=>val===true) &&
        <div className={cx("mobile-wrapper")} style={styleBack}>
            <img className={cx("intro-back-slash")} src={isLoaded.card?backPic:undefined} alt={"back-sprite"}/>
            <div className={cx("intro-text-mobile")}>
                <h1>Tobias Michel<span className={cx("sig-dot")}>.</span></h1>
                <h1>22 y/o<span className={cx("sig-dot")}>.</span></h1>
                <h1>Frankfurt am Main, Germany<span className={cx("sig-dot")}>.</span></h1>
            </div>
            <div className={cx("me-interest-wrapper-mobile")}>
                <div className={cx("interests-wrapper-mobile")}>
                    <div className={cx("interests-header-mobile")}>
                        <div className={cx("interest-header-line-mobile")}/>
                        <div className={cx("interest-header-title")}>
                            <h3>interests</h3>
                        </div>
                        <div  className={cx("interest-header-line-mobile")}/>
                    </div>
                    <div className={cx("interests-mobile")}>
                        <p>Finance.</p>
                        <p>Accounting.</p>
                        <p>Webdesign.</p>
                        <p>Coding.</p>
                        <p>Data Science.</p>
                        <p>Music.</p>
                    </div>
                </div>
                <div className={cx("me-wrapper-mobile")}>
                    {/* <h1 className={cx("me-subtext-mobile")}>That's me<span className={cx("sig-dot")}>.</span></h1> */}
                    <div className={cx("me-pic-container-mobile")}>
                        <img className={cx("me-picture-mobile")} src={isLoaded.me?mePic:undefined} alt="me." />
                        <div className={cx("me-socials-wrapper-mobile")}>
                            <a href="https://www.instagram.com/_tobi.michel_/">
                                <img className={cx("me-social-mobile")} src={Instagram} alt="Instagram" />
                            </a>
                            <a href="https://twitter.com/TobiasMichel12">
                                <img className={cx("me-social-mobile")} src={Twitter} alt="Twitter" />
                            </a>
                            <a href="https://www.linkedin.com/in/tobias-michel-756468217/">
                                <img className={cx("me-social-mobile")} src={LinkedIn} alt="LinkedIn" />
                            </a>
                            <a href="https://www.xing.com/profile/Tobias_Michel33/cv">
                                <img className={cx("me-social-mobile")} src={Xing} alt="Xing" />
                            </a>
                            <a href="https://github.com/mitscheltobi">
                                <img className={cx("me-social-mobile")} src={Git} alt="Git Hub" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className={cx("headers-mobile")}>Skills<span className={cx("sig-dot")}>...............................................................</span></h1>
            <div className={cx("skills-container-mobile")}>
                <div className={cx("skills-wrapper-mobile")}>
                    <h1 className={cx("skills-lang")}>Languages<span className={cx("sig-dot")}>.</span></h1>
                    <Rating rateName='German (1st language)' rateCount={5} mobile={true}/>
                    <Rating rateName='English (C2)' rateCount={4} mobile={true}/>
                    <h1 className={cx("skills-code")}>Programming & Related<span className={cx("sig-dot")}>.</span></h1>
                    <Rating rateName='Python' rateCount={5} mobile={true}/>
                    <Rating rateName='C#' rateCount={2} mobile={true}/>
                    <Rating rateName='Javascript/Node.js' rateCount={3} mobile={true}/>
                    <Rating rateName='React' rateCount={4} mobile={true}/>
                    <Rating rateName='Bash' rateCount={3} mobile={true}/>
                    <Rating rateName='Visual Basic' rateCount={4} mobile={true}/>
                    <Rating rateName='HTML 5' rateCount={3} mobile={true}/>
                    <Rating rateName='CCS 3' rateCount={3} mobile={true}/>
                    <Rating rateName='MySQL' rateCount={2} mobile={true}/>
                    <Rating rateName='PostgreSQL' rateCount={1} mobile={true}/>
                    <h1 className={cx("skills-softw")}>Software & Systems<span className={cx("sig-dot")}>.</span></h1>
                    <Rating rateName='Microsoft Excel' rateCount={5} mobile={true}/>    
                    <Rating rateName='Microsoft Word' rateCount={5} mobile={true}/>    
                    <Rating rateName='Microsoft PowerPoint' rateCount={5} mobile={true}/>
                    <Rating rateName='Adobe Illustrator' rateCount={4} mobile={true}/>
                    <Rating rateName='Adobe Photoshop' rateCount={2} mobile={true}/>
                    <Rating rateName='Adobe AfterEffects' rateCount={3} mobile={true}/>
                    <Rating rateName='Adobe XD' rateCount={5} mobile={true}/>
                    <Rating rateName='Adobe PremierePro' rateCount={2} mobile={true}/>
                    <Rating rateName='SAP CO' rateCount={3} mobile={true}/>
                    <Rating rateName='SAP BW' rateCount={3} mobile={true}/>
                    <Rating rateName='FL Studio 20' rateCount={5} mobile={true}/>
                    <Rating rateName='Git' rateCount={2} mobile={true}/>
                    <Rating rateName='Amazon AWS' rateCount={2} mobile={true}/>
                    <Rating rateName='Unix Based Systems (Linux)' rateCount={4} mobile={true}/>
                    <Rating rateName='Windows 10' rateCount={5} mobile={true}/>
                </div>
            </div>
            <div className={cx("cv-wrapper-mobile")}>
                <h1 className={cx("headers-mobile")}>CV<span className={cx("sig-dot")}>............................................................</span></h1>
                <div className={cx("cv-container-mobile")}>
                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"08/2010 - 06/2018"}
                        cvHeader={"High School/ College"}
                        cvSubHeader1={"Melanchthon-Schule Steinatal"}
                        cvSubLink={"https://www.melanchthon-schule.de/"}
                        cvSubHeader2={"Higher Education Entrance Qualification (A-levels/Abitur)"}
                        cvSubHeader3={"Final Grade: 2.1"}
                        mobile={true}/>

                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"09/2018 - 09/2022"}
                        cvHeader={"University"}
                        cvSubHeader1={"Hochschule Bremen - City University of Applied Sciences"}
                        cvSubLink={"https://www.hs-bremen.de/internet/en/hsb/"}
                        cvSubHeader2={"European Finance & Accounting"}
                        cvSubHeader3={"Bachelor of Arts"}
                        mobile={true}/>

                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"04/2020 - 08/2020"}
                        cvHeader={"Internship"}
                        cvSubHeader1={"Commerzbank AG"}
                        cvSubLink={"https://www.commerzbank.com/"}
                        cvSubHeader2={"Group Controlling"}
                        cvSubHeader3={"Group Cost Allocation"}
                        mobile={true}/>

                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"09/2020 - 09/2022"}
                        cvHeader={"Working Student"}
                        cvSubHeader1={"Commerzbank AG"}
                        cvSubLink={"https://www.commerzbank.com/"}
                        cvSubHeader2={"Group Controlling"}
                        cvSubHeader3={"Group Cost Allocation"}
                        mobile={true}/>
                    
                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"09/2020 - 06/2021"}
                        cvHeader={"University"}
                        cvSubHeader1={"Leeds Beckett University"}
                        cvSubLink={"https://www.leedsbeckett.ac.uk/"}
                        cvSubHeader2={"Erasmus Exchange Year"}
                        cvSubHeader3={"Leeds, UK"}
                        mobile={true}/>
                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvDate={"09/2022 - now."}
                        cvHeader={"University"}
                        cvSubHeader1={"Goethe University Frankfurt"}
                        cvSubLink={"https://www.goethe-university-frankfurt.de"}
                        cvSubHeader2={"Computer Science"}
                        cvSubHeader3={"Bachelor of Science, B.Sc"}
                        mobile={true}/>
                    <div className={cx("cv-element-marker-mobile")}/>
                    <CvElement
                        cvClassName={cx("cv-element-last")}
                        cvDate={"11/2022 - now."}
                        cvHeader={"Working Student"}
                        cvSubHeader1={"SKAD Advisory"}
                        cvSubLink={"https://www.sk-advisory.com/"}
                        cvSubHeader2={"Internal Projects"}
                        mobile={true}/>
                    <div className={cx("cv-vertical-line")}/>
                </div>
                <a href={cvPDF} download="tobias-michel-cv.pdf" className={cx("cv-button-wrapper-mobile")}>Download CV</a>
            </div>
            <div className={cx("contact-wrapper-mobile")}>
                <h1 className={cx("headers-mobile")}>Contact<span className={cx("sig-dot")}>............................................................</span></h1>
                <div>
                    {/* <span className={cx("contact-text-hover-mobile")}>contact me<span className={cx("sig-dot")}>.</span></span> */}
                    <div className={cx("contact-icons-mobile")}>
                        <a href="mailto:mail@tobias-michel.com">
                            <img className={cx("me-social-mobile")} src={Email} alt="E-Mail" />
                        </a>
                        <a href="https://www.instagram.com/_tobi.michel_/">
                            <img className={cx("me-social-mobile")} src={Instagram} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/TobiasMichel12">
                            <img className={cx("me-social-mobile")} src={Twitter} alt="Twitter" />
                        </a>
                        <a href="https://www.linkedin.com/in/tobias-michel-756468217/">
                            <img className={cx("me-social-mobile")} src={LinkedIn} alt="LinkedIn" />
                        </a>
                        <a href="https://www.xing.com/profile/Tobias_Michel33/cv">
                            <img className={cx("me-social-mobile")} src={Xing} alt="Xing" />
                        </a>
                        <a href="https://github.com/mitscheltobi">
                            <img className={cx("me-social-mobile")} src={Git} alt="Git Hub" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mobile;