import React, {useEffect} from 'react';
import styles from './CSS/CvElement.css';
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Link from '../Resources/link.png';

export const CvElement = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    const genAnim = (delay, fromLeft) => {
        if (fromLeft) {
            return(
                {
                    delay: 10,
                    visible: { opacity: 1, x:0, transition: {
                        duration: 0.75,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x:-150}
                }
            )} else {
            return(
                {
                    delay: 10,
                    visible: { opacity: 1, x:0, transition: {
                        duration: 0.75,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x:150}
                }
            )
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    let elementName=""
    props.cvClassName === undefined ? elementName=cx(props.mobile?"cv-element-mobile":"cv-element") : elementName=cx(props.mobile?"cv-element-mobile":"cv-element", props.cvClassName)

    const styling= {
        marginLeft: props.marginLeft
    }

    if(props.mobile){
        return(
            <div className={elementName} style={styling}>
                <div className={cx("cv-element-txt-mobile")}>
                    <span className={cx("cv-element-date-mobile")}>{props.cvDate}<span className={cx("sig-dot")}>. </span></span>
                    {props.cvHeader}<span className={cx("sig-dot")}>.</span><br/>
                    <a className={cx("cv-element-sublink")} href={props.cvSubLink}>{props.cvSubHeader1}</a><span className={cx("sig-dot")}>.</span><br/>
                    {props.cvSubHeader2}<span className={cx("sig-dot")}>.</span><br/>
                    {props.cvSubHeader3}{props.cvSubHeader3&&<span className={cx("sig-dot")}>.</span>}
                </div>
            </div>
        )
    } else {
        return(
            <motion.div ref={ref} variants={genAnim(props.animDelay, props.animFromLeft)} initial="hidden" animate={control} className={elementName} style={styling}>
                <div className={cx("cv-element-marker")}/>
                <div className={cx("cv-element-txt")}>
                    <div className={cx("cv-element-date-wrapper")}>
                        <span className={cx("cv-element-date")}>{props.cvDate[0]}<br/>-<br/>{props.cvDate[1]}</span>
                    </div>
                    <div className={cx("cv-top-text")}>{props.cvHeader}<span className={cx("sig-dot")}>.</span></div>
                    <div className={cx("cv-element-subtext")}>
                        <div className={cx("cv-sub-h1")}>{props.cvSubHeader1}{props.cvSubHeader1&&<span className={cx("sig-dot")}>.</span>}</div>
                        <div className={cx("cv-sub-h2")}>{props.cvSubHeader2}{props.cvSubHeader2&&<span className={cx("sig-dot")}>.</span>}</div>
                        <div className={cx("cv-sub-h3")}>{props.cvSubHeader3}{props.cvSubHeader3&&<span className={cx("sig-dot")}>.</span>}</div>
                    </div>
                    <div className={cx("cv-link-container")}>
                        <a className={cx("cv-link")} href={props.cvSubLink}>
                            <img src={Link} className={cx("cv-link-icon")}/>
                        </a>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default CvElement;