import React, {useEffect} from 'react';
import styles from './CSS/Rating.css'
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Rating = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    const genAnim = (delay) => {
        return {
            visible: { opacity: 1, x:0, transition: {
                duration: 0.4,
                ease: "easeInOut",
                delay: delay
            }},
            hidden: { opacity: 0, x:-50}
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    const rating = []

    for (let i= 0; i < props.rateCount; i++) {
        rating.push(
            props.mobile?<div className={cx(props.info?"rating-dot-info":"rating-dot-mobile","marked")} key={i.toString()}/>
            :
            <motion.div ref={ref} variants={genAnim(i*0.1+props.baseDelay)} initial="hidden" animate={control} className={cx(props.info?"rating-dot-info":"rating-dot","marked")} key={i.toString()}/>
            )
    }
    
    for (let x= 0; x < 5 - props.rateCount; x++) {
        rating.push(
            props.mobile?<div className={cx(props.info?"rating-dot-info":"rating-dot-mobile","unmarked")} key={(x+props.rateCount).toString()}/>
            :
            <motion.div ref={ref} variants={genAnim((props.rateCount+x)*0.1+props.baseDelay)} initial="hidden" animate={control} className={cx(props.info?"rating-dot-info":"rating-dot","unmarked")} key={(x+props.rateCount).toString()}/>
            )
    }

    if (props.extraButton) {
        return(
            <div className={cx("rating-wrapper-w-button")}>
                <motion.p className={cx(props.info?"rating-caption-info":"rating-caption")} ref={ref} variants={genAnim(props.baseDelay)} initial="hidden" animate={control}>{props.rateName}</motion.p>
                <div className={cx(props.info?"rating-points-info":"rating-points")}>
                    {rating}
                </div>
            </div>
        )
    } else if (props.mobile) {
        return(
            <div className={cx("rating-wrapper-mobile")}>
                <p className={cx("rating-caption-mobile")}>{props.rateName}</p>
                <div className={cx("rating-points-mobile")}>
                    {rating}
                </div>
            </div>
        )
    } else {
        return(
            <div className={cx(props.info?"rating-wrapper-info":"rating-wrapper")}>
                <motion.p className={cx(props.info?"rating-caption-info":"rating-caption")} ref={ref} variants={genAnim(props.baseDelay)} initial="hidden" animate={control}>{props.rateName}</motion.p>
                <div className={cx(props.info?"rating-points-info":"rating-points")}>
                    {rating}
                </div>
            </div>
        )
    }
}

export default Rating;