import React from 'react'
import styles from './App.css';
import Home from './Components/Home';
import classNames from 'classnames/bind';

function App() {
  const cx = classNames.bind(styles)
  return (
    <div className={cx("App")}>
      <Home/>
    </div>
  );
}

export default App;