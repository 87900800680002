import React from 'react';
import styles from './CSS/Nav.css'
import {Link} from 'react-scroll';
import classNames from 'classnames/bind';

export const Nav = (props) => {
    const cx = classNames.bind(styles)
    return(
        <div className={cx("nav-wrapper")}>
            <div className={cx("nav-container-container")}>
                <div className={cx("nav-container-buttons")}>
                    <Link className={cx("nav-element")} activeClass="active" to="1" spy={true} smooth={true} duration={750}>
                        {props.curPage===0 ? <div className={cx("nav-button", "nav-button-selected")}/> : <div className={cx("nav-button","nav-button-unselected")}/>}
                    </Link>

                    <Link className={cx("nav-element")} activeClass="active" to="2" spy={true} smooth={true} duration={750}>
                        {props.curPage===1 ? <div className={cx("nav-button", "nav-button-selected")}/> : <div className={cx("nav-button","nav-button-unselected")}/>}
                    </Link>

                    <Link className={cx("nav-element")} activeClass="active" to="3" spy={true} smooth={true} duration={750}>
                        {props.curPage===2 ? <div className={cx("nav-button", "nav-button-selected")}/> : <div className={cx("nav-button","nav-button-unselected")}/>}
                    </Link>

                    <Link className={cx("nav-element")} activeClass="active" to="4" spy={true} smooth={true} duration={750}>
                        {props.curPage===3 ? <div className={cx("nav-button", "nav-button-selected")}/> : <div className={cx("nav-button","nav-button-unselected")}/>}
                    </Link>
                    
                    <Link className={cx("nav-element")} activeClass="active" to="5" spy={true} smooth={true} duration={750}>
                        {props.curPage===4 ? <div className={cx("nav-button", "nav-button-selected")}/> : <div className={cx("nav-button","nav-button-unselected")}/>}
                    </Link>
                </div>
                <div className={cx("nav-container-text")}>
                    <Link activeClass="active" to="1" spy={true} smooth={true} duration={750}>
                        {props.curPage===0 ? <div className={cx("nav-text", "nav-text-selected")}>me.</div> : <div className={cx("nav-text","nav-text-unselected")}>me.</div> }
                    </Link>
                    <Link activeClass="active" to="2" spy={true} smooth={true} duration={750}>
                        {props.curPage===1 ? <div className={cx("nav-text", "nav-text-selected")}>skills.</div> : <div className={cx("nav-text","nav-text-unselected")}>skills.</div> }
                    </Link>
                    <Link activeClass="active" to="3" spy={true} smooth={true} duration={750}>
                        {props.curPage===2 ? <div className={cx("nav-text", "nav-text-selected")}>portfolio.</div> : <div className={cx("nav-text","nav-text-unselected")}>portfolio.</div> }
                    </Link>
                    <Link activeClass="active" to="4" spy={true} smooth={true} duration={750}>
                        {props.curPage===3 ? <div className={cx("nav-text", "nav-text-selected")}>cv.</div> : <div className={cx("nav-text","nav-text-unselected")}>cv.</div> }
                    </Link>
                    <Link activeClass="active" to="5" spy={true} smooth={true} duration={750}>
                        {props.curPage===4 ? <div className={cx("nav-text", "nav-text-selected")}>contact.</div> : <div className={cx("nav-text","nav-text-unselected")}>contact.</div> }
                    </Link>
                </div>
                <div className={cx("nav-vert-line")} />
            </div>
        </div>
    )
}

export default Nav;