import React, {useState} from 'react';
import styles from './CSS/EmailPopup.css';
import CloseButton from '../Resources/close-button.png';
import Copy_idle from '../Lotties/Contact_Icons/Clipboard_idle.json'
import Copy_click from '../Lotties/Contact_Icons/Clipboard_click.json'
import Copy_declick from '../Lotties/Contact_Icons/Clipboard_rev.json'
import Lottie from 'react-lottie';
import classNames from 'classnames/bind';

export const EmailPopup = (props) => {
    const cx = classNames.bind(styles)
    const [anim, setAnim] = useState(Copy_idle)

    const genAnimOpt = (item) => {
        return {
            loop: false,
            autoplay: false,
            animationData: item,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
    }

    return(
        <div className={cx("contact-email-window-wrapper")}>
            <div className={cx("contact-email-window-filler")}/>
            <div className={cx("contact-email-window-container")}>
                <h3 className={cx("contact-email-window-text")}>mail@tobias-michel.com</h3>
                <button className={cx("contact-email-window-copy-wrapper")} onClick={()=> {navigator.clipboard.writeText("mail@tobias-michel.com"); setTimeout(() => {setAnim(Copy_declick)}, 3000); setAnim(Copy_click)}}>
                    <Lottie className={cx("contact-email-window-copy")} options={genAnimOpt(anim)}/>
                </button>
                <img className={cx("contact-email-window-close")} alt="close" src={CloseButton} onClick={() => {props.closeHandle(false); props.resetHover('dehover')}}/>
            </div>
        </div>
    )
}

export default EmailPopup;