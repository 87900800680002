import React, {useEffect} from 'react';
import Nav from './Nav';
import CvElement from './CvElement';
import styles from './CSS/Scene4.css';
import cvPDF from '../Resources/CV-Tobias_Michel.pdf'
// import BackgroundCard4 from '../Resources/BackgroundCard4.png';
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Scene4 = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    const enumerateDelay = (num) => {
        const time = 1
        const max = 8
        return (time/max)*num
    }

    const animData = {
        visible: { opacity: 1, x:0, transition: {
            duration: 0.4,
            ease: "easeInOut",
            delay: enumerateDelay(8)
        }},
        hidden: { opacity: 0, x:100}
    }

    const genAnimBack = (delay, triangleID) => {
        switch (triangleID){
            case 1: {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, rotate: 50, transition: {
                        duration: 3,
                        ease: "easeOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: 100, y: -150, rotate: 0, scale: 1 }
                }
            }
            case 2: {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, rotate: 75, transition: {
                        duration: 3.5,
                        ease: "easeOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: 200, y: 400, rotate: 0, scale: 1 }
                }
            }
            case 3: {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, rotate: 24, transition: {
                        duration: 2.5,
                        ease: "easeOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: 250, y: 350, rotate: -50, scale: 0.8 }
                }
            }
            case 4: {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, rotate: 70, transition: {
                        duration: 3,
                        ease: "easeOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: 100, y: 250, rotate: 110, scale: 0.8 }
                }
            }
            default:
                return null
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    const styleBack = {
        // background: `url(${BackgroundCard4})`,
        background: `#212121`,
        height: '90%',
        width: '95%',
        backgroundColor: '#212121',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    return(
        <div style={styleBack} className={cx("scene","scene4")}>
            <div className={cx("cv-wrapper")}>
                <motion.div ref={ref} style={{ originX: .5, originY: .5 }} variants={genAnimBack(0,1)} initial="hidden" animate={control} className={cx("cv-back-triangle-1-container")}>
                    <svg className={cx("cv-back-triangle-1-svg")}viewBox="0 0 130 117">
                        <polygon className={cx("cv-back-triangle-1")} stroke-linejoin="round" points="65,15 15,101.6 115,101.6"/>
                    </svg>
                </motion.div>

                <motion.div ref={ref} style={{ originX: .5, originY: .5 }} variants={genAnimBack(0,2)} initial="hidden" animate={control} className={cx("cv-back-triangle-2-container")}>
                    <svg className={cx("cv-back-triangle-2-svg")}viewBox="0 0 130 117">
                        <polygon className={cx("cv-back-triangle-2")} stroke-linejoin="round" points="65,15 15,101.6 115,101.6"/>
                    </svg>
                </motion.div>
                
                <motion.div ref={ref} style={{ originX: .5, originY: .5 }} variants={genAnimBack(1,3)} initial="hidden" animate={control} className={cx("cv-back-triangle-3-container")}>
                    <svg className={cx("cv-back-triangle-3-svg")}viewBox="0 0 130 117">
                        <polygon className={cx("cv-back-triangle-3")} stroke-linejoin="round" points="65,15 15,101.6 115,101.6"/>
                    </svg>
                </motion.div>

                <motion.div ref={ref} style={{ originX: .5, originY: .5 }} variants={genAnimBack(0.3,4)} initial="hidden" animate={control} className={cx("cv-back-triangle-4-container")}>
                    <svg className={cx("cv-back-triangle-4-svg")}viewBox="0 0 130 117">
                        <polygon className={cx("cv-back-triangle-4")} stroke-linejoin="round" points="65,15 15,101.6 115,101.6"/>
                    </svg>
                </motion.div>

                <div className={cx("cv-container")}>
                    <CvElement
                        cvDate={["08/2010", "06/2018"]}
                        cvHeader={"High School"}
                        cvSubHeader1={"Melanchthon-Schule Steinatal"}
                        cvSubLink={"https://www.melanchthon-schule.de/"}
                        cvSubHeader2={"Abitur"}
                        cvSubHeader3={"Final Grade: 2.1"}
                        marginLeft={"10rem"}
                        animDelay={enumerateDelay(1)}
                        animFromLeft={true}
                        />

                    <CvElement
                        cvDate={["09/2018", "09/2022"]}
                        cvHeader={"University"}
                        cvSubHeader1={"Hochschule Bremen"}
                        cvSubLink={"https://www.hs-bremen.de/internet/en/hsb/"}
                        cvSubHeader2={"European Finance & Accounting"}
                        cvSubHeader3={"Bachelor of Arts"}
                        marginLeft={"25rem"}
                        animDelay={enumerateDelay(2)}
                        animFromLeft={false}
                        />

                    <CvElement
                        cvDate={["04/2020", "08/2020"]}
                        cvHeader={"Internship"}
                        cvSubHeader1={"Commerzbank AG"}
                        cvSubLink={"https://www.commerzbank.com/"}
                        cvSubHeader2={"Group Controlling"}
                        cvSubHeader3={"Group Cost Allocation"}
                        marginLeft={"2.5rem"}
                        animDelay={enumerateDelay(3)}
                        animFromLeft={true}
                        />

                    <CvElement
                        cvDate={["09/2020", "09/2022"]}
                        cvHeader={"Working Student"}
                        cvSubHeader1={"Commerzbank AG"}
                        cvSubLink={"https://www.commerzbank.com/"}
                        cvSubHeader2={"Group Controlling"}
                        cvSubHeader3={"Group Cost Allocation"}
                        marginLeft={"-15rem"}
                        animDelay={enumerateDelay(4)}
                        animFromLeft={false}
                        />

                    <CvElement
                        cvDate={["09/2020", "06/2021"]}
                        cvHeader={"University"}
                        cvSubHeader1={"Leeds Beckett University"}
                        cvSubLink={"https://www.leedsbeckett.ac.uk/"}
                        cvSubHeader2={"Erasmus Exchange Year"}
                        cvSubHeader3={"Leeds, UK"}
                        marginLeft={"-5rem"}
                        animDelay={enumerateDelay(5)}
                        animFromLeft={true}
                        />

                    <CvElement
                        cvDate={["09/2022", "now"]}
                        cvHeader={"University"}
                        cvSubHeader1={"Goethe University Frankfurt"}
                        cvSubLink={"https://www.goethe-university-frankfurt.de"}
                        cvSubHeader2={"Computer Science"}
                        cvSubHeader3={"Bachelor of Science, B.Sc"}
                        marginLeft={"15rem"}
                        animDelay={enumerateDelay(6)}
                        animFromLeft={false}
                        />

                    <CvElement
                        cvClassName={cx("cv-element-last")}
                        cvDate={["11/2022", "now"]}
                        cvHeader={"Working Student"}
                        cvSubHeader1={"SKAD Advisory"}
                        cvSubLink={"https://www.sk-advisory.com/"}
                        cvSubHeader2={"Internal Projects"}
                        marginLeft={"0"}
                        animDelay={enumerateDelay(7)}
                        animFromLeft={true}
                        />

                    <motion.a ref={ref} variants={animData} initial="hidden" animate={control} href={cvPDF} download="tobias-michel-cv.pdf" className={cx("cv-button-wrapper")}>
                        <button className={cx("cv-button")}>Download CV</button>
                    </motion.a>
                </div>
            </div>
            <Nav curPage={3}/>
        </div>
    )
}

export default Scene4;