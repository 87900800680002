import React, {useState, useEffect} from 'react'
import Nav from './Nav'
import styles from './CSS/Scene5.css'
import BackgroundCard5 from '../Resources/BackgroundCard5.png'
import EmailPopup from './EmailPopup.js'
import ConactIcon from './ContactIcon.js'
import ContactForm from './ContactForm.js'
import Mail_idle from '../Lotties/Contact_Icons/Mail_Idle.json'
import Mail_hover from '../Lotties/Contact_Icons/Mail_hover.json'
import Mail_dehover from '../Lotties/Contact_Icons/Mail_dehover.json'
import Twitter_idle from '../Lotties/Contact_Icons/Twitter_idle.json'
import Twitter_hover from '../Lotties/Contact_Icons/Twitter_hover.json'
import Twitter_dehover from '../Lotties/Contact_Icons/Twitter_dehover.json'
import Xing_idle from '../Lotties/Contact_Icons/Xing_Idle.json'
import Xing_hover from '../Lotties/Contact_Icons/Xing_hover.json'
import Xing_dehover from '../Lotties/Contact_Icons/Xing_dehover.json'
import LinkedIn_idle from '../Lotties/Contact_Icons/LinkedIn_Idle.json'
import LinkedIn_hover from '../Lotties/Contact_Icons/LinkedIn_hover.json'
import LinkedIn_dehover from '../Lotties/Contact_Icons/LinkedIn_dehover.json'
import Insta_idle from '../Lotties/Contact_Icons/Insta_Idle.json'
import Insta_hover from '../Lotties/Contact_Icons/Insta_hover.json'
import Insta_dehover from '../Lotties/Contact_Icons/Insta_dehover.json'
import Form_idle from '../Lotties/Contact_Icons/Form_Idle.json'
import Form_hover from '../Lotties/Contact_Icons/Form_hover.json'
import Form_dehover from '../Lotties/Contact_Icons/Form_dehover.json'
import Form_send from '../Lotties/Contact_Icons/Form_send.json'
import classNames from 'classnames/bind'
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Scene5 = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();


    const genAnimData = (fromLeft, delay) => {
        if (fromLeft) {
            return(
                {
                    visible: { opacity: 1, scale: 1, x:0, transition: {
                        duration: 1,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, scale: .9, x: -300}
                }
            )
        } else {
            return(
                {
                    visible: { opacity: 1, scale: 1, x:0, transition: {
                        duration: 1,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, scale: .9, x: 300}
                }
            )
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    const [hover, setHover] = useState(false)
    const [hoverEmail, setEmail] = useState('idle')
    const [hoverForm, setForm] = useState('idle')
    const [hoverXing, setXing] = useState('idle')
    const [hoverLinkedIn, setLinkedIn] = useState('idle')
    const [hoverInstagram, setInstagram] = useState('idle')
    const [hoverTwitter, setTwitter] = useState('idle')
    const [emailClick, setEmailClick] = useState(false)
    const [FormClick, setFormClick] = useState(false)
    const [animOpt, setAnimOpt] = useState({})
    const [items, setItems] = useState(
        {
            email: false,
            form: false,
            xing: false,
            linkedin: false,
            insta: false,
            twitter: false,
    })

    const genAnimOpt = (item) => {
        return {
            loop: false,
            autoplay: true,
            animationData: item,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
    }
    
    useEffect(() => {
        const animList = {
            'FormHover': Form_hover,
            'FormDehover': Form_dehover,
            'FormIdle': Form_idle,
            'FormSend': Form_send,
            'XingHover': Xing_hover,
            'XingDehover': Xing_dehover,
            'XingIdle': Xing_idle,
            'LinkedInHover': LinkedIn_hover,
            'LinkedInDehover': LinkedIn_dehover,
            'LinkedInIdle': LinkedIn_idle,
            'InstaHover': Insta_hover,
            'InstaDehover': Insta_dehover,
            'InstaIdle': Insta_idle,
            'TwitterHover': Twitter_hover,
            'TwitterDehover': Twitter_dehover,
            'TwitterIdle': Twitter_idle,
            'MailHover': Mail_hover,
            'MailDehover': Mail_dehover,
            'MailIdle': Mail_idle,
        }
        setAnimOpt(Object.keys(animList).map(key => genAnimOpt(animList[key])))
    }, [])
    
    const styleBack = {
        background: `url(${BackgroundCard5})`,
        height: '90%',
        width: '95%',
        backgroundColor: '#242424',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    const deHoverAction = () => {
        setHover(false)
        setEmailClick(false)
        setFormClick(false)
        setEmail('idle')
        setForm('idle')
        setXing('idle')
        setLinkedIn('idle')
        setInstagram('idle')
        setTwitter('idle')
        for (const key of Object.entries(items)){
            setItems(prevState => ({...prevState, [key[0]]: false}))
        }
    }

    const hoverAction = () => {
        setHover(true)
        let i = 0
        for (const key of Object.entries(items)){
            i++;
            setTimeout(()=> {setItems(prevState => ({...prevState, [key[0]]: true}))}, i*100)
        }
    }
    
    return(
        <div style={styleBack} className={cx("scene","scene5")}>
            <div className={cx("contact-wrapper")}>
                <div className={cx("contact-circle-form")}>
                    <motion.div ref={ref} variants={genAnimData(false, .3)} initial="hidden" animate={control} className={cx("contact-small-circle")}/>
                    <motion.div ref={ref} variants={genAnimData(false, 0)} initial="hidden" animate={control} className={cx("contact-mid-circle")}/>
                    <div className={cx("contact-big-circle")} onMouseEnter={() =>{
                        hoverAction()
                    }}
                    onMouseLeave={() => {
                        !FormClick && deHoverAction()
                    }}>
                        {hover ?
                            <div className={cx("contact-hover")}>
                                <span className={cx("contact-text-hover")}>contact me<span className={cx("sig-dot")}>.</span></span>

                                {items.email && 
                                    <div className={cx("contact-icons-div", "contact-email")}>
                                            {
                                                {
                                                    'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => {!emailClick && setEmail('dehover')}} onClick={()=>{setEmailClick(!emailClick)}}>
                                                                    <ConactIcon disableLink={true} IconClass="contact-icon-email" IconAnim={animOpt[16]}/>
                                                                </div>,
                                                    'dehover':  <div className={cx("contact-link-wrapper")} onMouseEnter={() => {setEmail('hover')}}>
                                                                    <ConactIcon disableLink={true} IconClass="contact-icon-email" IconAnim={animOpt[17]}/>
                                                                </div>,
                                                    'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => {setEmail('hover')}}>
                                                                    <ConactIcon disableLink={true} IconClass="contact-icon-email" IconAnim={animOpt[18]}/>
                                                                </div>,
                                                }[hoverEmail]
                                            }
                                    </div>
                                }

                                {emailClick && <EmailPopup closeHandle={setEmailClick} resetHover={setEmail}/>}

                                {items.form && 
                                    <div className={cx("contact-icons-div","contact-form")}>
                                        {
                                            {
                                                'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => {!FormClick && setForm('dehover')}} onClick={()=>{setFormClick(!FormClick)}}>
                                                                <ConactIcon disableLink={true} IconClass="contact-icon-form" IconAnim={animOpt[0]}/>
                                                            </div>,
                                                'dehover':  <div className={cx("contact-link-wrapper")} onMouseEnter={() => {setForm('hover')}}>
                                                                <ConactIcon disableLink={true} IconClass="contact-icon-form" IconAnim={animOpt[1]}/>
                                                            </div>,
                                                'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => {setForm('hover')}}>
                                                                <ConactIcon disableLink={true} IconClass="contact-icon-form" IconAnim={animOpt[2]}/>
                                                            </div>,
                                                'send':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => {setForm('hover')}}>
                                                                <ConactIcon disableLink={true} IconClass="contact-icon-form" IconAnim={animOpt[3]}/>
                                                            </div>,
                                            }[hoverForm]
                                        }
                                    </div>
                                }

                                {FormClick && <ContactForm closeHandle={setFormClick} resetHover={setForm} deHover={deHoverAction}/>}

                                {items.xing && 
                                    <div className={cx("contact-icons-div","contact-xing")}>
                                        {
                                            {
                                                'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => setXing('dehover')}>
                                                                <ConactIcon IconLink="https://www.xing.com/profile/Tobias_Michel33/cv" IconClass="contact-icon-xing" IconAnim={animOpt[4]}/>
                                                            </div>,
                                                'dehover':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setXing('hover')}>
                                                                <ConactIcon IconLink="https://www.xing.com/profile/Tobias_Michel33/cv" IconClass="contact-icon-xing" IconAnim={animOpt[5]}/>
                                                            </div>,
                                                'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setXing('hover')}>
                                                                <ConactIcon IconLink="https://www.xing.com/profile/Tobias_Michel33/cv" IconClass="contact-icon-xing" IconAnim={animOpt[6]}/>
                                                            </div>,
                                            }[hoverXing]
                                        }
                                    </div>
                                }

                                {items.linkedin && 
                                    <div className={cx("contact-icons-div","contact-linkedin")}>
                                        {
                                            {
                                                'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => setLinkedIn('dehover')}>
                                                                <ConactIcon IconLink="https://www.linkedin.com/in/tobias-michel-756468217/" IconClass="contact-icon-linkedin" IconAnim={animOpt[7]}/>
                                                            </div>,
                                                'dehover':  <div className={cx("contact-link-wrapper")} onMouseEnter={() => setLinkedIn('hover')}>
                                                                <ConactIcon IconLink="https://www.linkedin.com/in/tobias-michel-756468217/" IconClass="contact-icon-linkedin" IconAnim={animOpt[8]}/>
                                                            </div>,
                                                'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setLinkedIn('hover')}>
                                                                <ConactIcon IconLink="https://www.linkedin.com/in/tobias-michel-756468217/" IconClass="contact-icon-linkedin" IconAnim={animOpt[9]}/>
                                                            </div>,
                                            }[hoverLinkedIn]
                                        }
                                    </div>
                                }

                                {items.insta && 
                                    <div className={cx("contact-icons-div","contact-instagram")}>
                                        {
                                            {
                                                'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => setInstagram('dehover')}>
                                                                <ConactIcon IconLink="https://www.instagram.com/_tobi.michel_/" IconClass="contact-icon-instagram" IconAnim={animOpt[10]}/>
                                                            </div>,
                                                'dehover':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setInstagram('hover')}>
                                                                <ConactIcon IconLink="https://www.instagram.com/_tobi.michel_/" IconClass="contact-icon-instagram" IconAnim={animOpt[11]}/>
                                                            </div>,
                                                'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setInstagram('hover')}>
                                                                <ConactIcon IconLink="https://www.instagram.com/_tobi.michel_/" IconClass="contact-icon-instagram" IconAnim={animOpt[12]}/>
                                                            </div>,
                                            }[hoverInstagram]
                                        }
                                    </div>
                                }

                                {items.twitter && 
                                    <div className={cx("contact-icons-div","contact-twitter")}>
                                        {
                                            {
                                                'hover':    <div className={cx("contact-link-wrapper")} onMouseLeave={() => setTwitter('dehover')}>
                                                                <ConactIcon IconLink="https://twitter.com/TobiasMichel12" IconClass="contact-icon-twitter" IconAnim={animOpt[13]}/>
                                                            </div>,
                                                'dehover':  <div className={cx("contact-link-wrapper")} onMouseEnter={() => setTwitter('hover')}>
                                                                <ConactIcon IconLink="https://twitter.com/TobiasMichel12" IconClass="contact-icon-twitter" IconAnim={animOpt[14]}/>
                                                            </div>,
                                                'idle':     <div className={cx("contact-link-wrapper")} onMouseEnter={() => setTwitter('hover')}>
                                                                <ConactIcon IconLink="https://twitter.com/TobiasMichel12" IconClass="contact-icon-twitter" IconAnim={animOpt[15]}/>
                                                            </div>,
                                            }[hoverTwitter]
                                        }
                                    </div>
                                }
                            </div>
                        :
                            <span className={cx("contact-text")}>Want to get in touch?</span>
                        }
                        </div>
                    <motion.div ref={ref} variants={genAnimData(true, 0)} initial="hidden" animate={control} className={cx("contact-mid-circle")}/>
                    <motion.div ref={ref} variants={genAnimData(true, .3)} initial="hidden" animate={control} className={cx("contact-small-circle")}/>
                </div>
            </div>
            <Nav curPage={4}/>
        </div>
    )
}

export default Scene5;