import React, {useState, useEffect} from 'react';
import Scene1 from './Scene1';
import Scene2 from './Scene2';
import Scene3 from './Scene3';
import Scene4 from './Scene4';
import Scene5 from './Scene5';
import Mobile from './Mobile';
// import CookieBanner from './CookieBanner';
import styles from './CSS/Home.css'
import classNames from 'classnames/bind';
// import useScrollSnap from 'react-use-scroll-snap';

export const Home = (props) => {
    const [mobile, setMobile] = useState(window.innerWidth>=window.innerHeight?false:true);
    const cx = classNames.bind(styles)
    
    useEffect(()=>{
        function handleWindowResize() {
            window.innerWidth>=window.innerHeight?setMobile(false):setMobile(true)
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    if(mobile) {
        return(
            <div className={cx("mobile-wrapper")}>
                <Mobile />
            </div>
        )} else {
            return(
                <div className={cx("home-wrapper")}>
                    <div>
                        <div className={cx("cards", "1")}>
                            <Scene1/>
                        </div>
                    
                        <div className={cx("cards", "2")}>
                            <Scene2/>
                        </div>
                    
                        <div className={cx("cards", "3")}>
                            <Scene3/>
                        </div>
    
                        <div className={cx("cards", "4")}>
                            <Scene4/>
                        </div>
    
                        <div className={cx("cards", "5")}>
                            <Scene5/>
                        </div>
                    </div>                    
                </div>
            )
        }
}

export default Home;