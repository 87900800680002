import React, {useEffect} from 'react';
import Nav from './Nav';
import Rating from './Rating';
import styles from './CSS/Scene2.css';
// import BackgroundCard2 from '../Resources/BackgroundCard2.png';
import classNames from 'classnames/bind';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const Scene2 = (props) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    const genAnim = (delay) => {
        return {
            visible: { opacity: 1, x:0, transition: {
                duration: 1,
                ease: "easeInOut",
                delay: delay
            }},
            hidden: { opacity: 0, x: 50}
        }
    }

    const genAnimBack = (delay, bubbleID) => {
        switch (bubbleID){
            case "big": {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, transition: {
                        duration: 2.5,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: -300, y: 150, scale: .9 }
                }
            }
            case "medium": {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, transition: {
                        duration: 3,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0, x: 150, y: 300, scale: 1.2 }
                }
            }
            case "small": {
                return {
                    visible: { opacity: 1, x:0, y:0, scale: 1, transition: {
                        duration: 2.5,
                        ease: "easeInOut",
                        delay: delay
                    }},
                    hidden: { opacity: 0.5, x: -50, y: -200, scale: 0.9 }
                }
            }
            default:
                return null
        }
    }

    useEffect(()=>{
        if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
    }, [control, inView]);

    const cx = classNames.bind(styles)
    const styleBack = {
        // background: `url(${BackgroundCard2})`,
        background: `#212121`,
        height: '90%',
        width: '95%',
        backgroundColor: '#242424',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    const enumerateDelay = (num) => {
        const time = 1
        const max = 40
        return (time/max)*num
    }

    return(
        <div style={styleBack} className={cx("scene","scene2")}>
            <div className={cx("skills-container")}>
                <motion.div ref={ref} variants={genAnimBack(0, "big")} initial="hidden" animate={control} className={cx("skills-back-circle-big")}/>
                <motion.div ref={ref} variants={genAnimBack(0, "medium")} initial="hidden" animate={control} className={cx("skills-back-circle-medium")}/>
                <motion.div ref={ref} variants={genAnimBack(0.5, "small")} initial="hidden" animate={control} className={cx("skills-back-circle-small")}/>
                <div className={cx("skills-wrapper")}>
                    <motion.h1 ref={ref} variants={genAnim(0)} initial="hidden" animate={control} className={cx("skills-lang")}>Languages<span className={cx("sig-dot")}>.</span></motion.h1>
                    <Rating rateName='German (1st language)' rateCount={5} baseDelay={enumerateDelay(1)}/> 
                    <Rating rateName='English (C2)' rateCount={4} baseDelay={enumerateDelay(2)}/>
                    <motion.h1 ref={ref} variants={genAnim(enumerateDelay(2))} initial="hidden" animate={control} className={cx("skills-code")}>Programming & Related<span className={cx("sig-dot")}>.</span></motion.h1>
                    <Rating rateName='Python' rateCount={5} baseDelay={enumerateDelay(3)}/>
                    <Rating rateName='C#' rateCount={2} baseDelay={enumerateDelay(4)}/>
                    <Rating rateName='Javascript/Node.js' rateCount={3} baseDelay={enumerateDelay(5)}/>
                    <Rating rateName='React' rateCount={4} baseDelay={enumerateDelay(6)}/>
                    <Rating rateName='Bash' rateCount={3} baseDelay={enumerateDelay(7)}/>
                    <Rating rateName='Visual Basic' rateCount={4} baseDelay={enumerateDelay(8)}/>
                    <Rating rateName='HTML 5' rateCount={3} baseDelay={enumerateDelay(9)}/>
                    <Rating rateName='CCS 3' rateCount={3} baseDelay={enumerateDelay(10)}/>
                    <Rating rateName='MySQL' rateCount={2} baseDelay={enumerateDelay(11)}/>
                    <Rating rateName='PostgreSQL' rateCount={1} baseDelay={enumerateDelay(12)}/>
                    <motion.h1 ref={ref} variants={genAnim(enumerateDelay(12))} initial="hidden" animate={control} className={cx("skills-softw")}>Software & Systems<span className={cx("sig-dot")}>.</span></motion.h1>
                    <Rating rateName='Microsoft Excel' rateCount={5} baseDelay={enumerateDelay(13)}/>    
                    <Rating rateName='Microsoft Word' rateCount={5} baseDelay={enumerateDelay(14)}/>    
                    <Rating rateName='Microsoft PowerPoint' rateCount={5} baseDelay={enumerateDelay(15)}/>
                    <Rating rateName='Adobe Illustrator' rateCount={4} baseDelay={enumerateDelay(16)}/>
                    <Rating rateName='Adobe Photoshop' rateCount={2} baseDelay={enumerateDelay(17)}/>
                    <Rating rateName='Adobe AfterEffects' rateCount={3} baseDelay={enumerateDelay(18)}/>
                    <Rating rateName='Adobe XD' rateCount={5} baseDelay={enumerateDelay(19)}/>
                    <Rating rateName='Adobe PremierePro' rateCount={2} baseDelay={enumerateDelay(20)}/>
                    <Rating rateName='SAP CO' rateCount={3} baseDelay={enumerateDelay(21)}/>
                    <Rating rateName='SAP BW' rateCount={3} baseDelay={enumerateDelay(22)}/>
                    <Rating rateName='FL Studio 20' rateCount={5} baseDelay={enumerateDelay(23)}/>
                    <Rating rateName='Git' rateCount={2} baseDelay={enumerateDelay(24)}/>
                    <Rating rateName='Amazon AWS' rateCount={2} baseDelay={enumerateDelay(25)}/>
                    <Rating rateName='Unix Based Systems (Linux)' rateCount={4} baseDelay={enumerateDelay(26)}/>
                    <Rating rateName='Windows 10' rateCount={5} baseDelay={enumerateDelay(27)}/>
                </div>
            </div>
            <Nav curPage={1}/>
            <div className={cx("explenation-container")}>
                <div className={cx("explenation-wrapper")}>
                    <Rating info={true} rateName='I have used it before (limited knowledge).' rateCount={1} baseDelay={enumerateDelay(36)}/>
                    <Rating info={true} rateName='I know basic concepts/functionality.' rateCount={2} baseDelay={enumerateDelay(37)}/>
                    <Rating info={true} rateName='I use it regularly.' rateCount={3} baseDelay={enumerateDelay(38)}/>
                    <Rating info={true} rateName='I have proficient knowledge.' rateCount={4} baseDelay={enumerateDelay(39)}/>
                    <Rating info={true} rateName='I have extensive knowledge.' rateCount={5} baseDelay={enumerateDelay(40)}/>
                </div>
            </div>
        </div>
    )
}

export default Scene2;