import React from 'react';
import Lottie from 'react-lottie';
import styles from './CSS/ContactIcon.css'
import classNames from 'classnames/bind';

export const ContactIcon = (props) => {
    const cx = classNames.bind(styles)
    if (props.disableLink){
        return(
           <button className={cx("contact-icon-nolink")}>
               <Lottie className={cx("contact-icon", props.IconClass)}
               options={props.IconAnim}/>
            </button>
        )
    } else {
        return(
           <a className={cx("contact-icon-link")} href={props.IconLink}>
               <Lottie className={cx("contact-icon", props.IconClass)}
               options={props.IconAnim}/>
            </a>
        )
    }
}

export default ContactIcon;